@tailwind base;
@tailwind components;
@tailwind utilities;


.react-datepicker-wrapper , .react-datepicker-wrapper input {
    width: 100% !important;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
  }
  
  .close {
    float: right;
    cursor: pointer;
  }
  
  .grid {
    display: grid;
    gap: 1rem;
  }
  
  .grid-cols-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  
  @media (min-width: 640px) {
    .sm\:grid-cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 768px) {
    .md\:grid-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .scroll { 
    max-width: 200px;
    max-height: 50px;
    overflow-y: scroll; 
  }

/* styles.css or your preferred stylesheet */
.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.responsive-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.responsive-table th, .responsive-table td {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #ddd;
  min-width: 200px; /* Set minimum width for each cell */
}

@media (max-width: 768px) {
  .responsive-table th, .responsive-table td {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .responsive-table th, .responsive-table td {
    white-space: nowrap;
  }
}

.grid-cols-16 {
  grid-template-columns: repeat(16, 1fr);
}